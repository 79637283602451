import { render, staticRenderFns } from "./LessonRegistrationEdit.vue?vue&type=template&id=3ba9ced1&scoped=true&"
import script from "./LessonRegistrationEdit.vue?vue&type=script&lang=js&"
export * from "./LessonRegistrationEdit.vue?vue&type=script&lang=js&"
import style0 from "./LessonRegistrationEdit.vue?vue&type=style&index=0&id=3ba9ced1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba9ced1",
  null
  
)

export default component.exports