<template>
  <div>
    <div class="mb-2">
      <b-breadcrumb
        :items="breadcrumbItems"
      />
    </div>
    <b-row>
      <b-col lg="8">
        <h2
          v-if="$route.query.met === 'edit'"
        >
          登録講座の編集
        </h2>
        <h2
          v-else
        >
          講座の新規登録
        </h2>
      </b-col>
    </b-row>
    <b-alert
      variant="primary"
      show
      class=""
    >
      <div class="alert-body mt-1 mb-2">
        <div class="d-flex">
          <div class="">
            <b-avatar
              size="40"
              src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
              variant="light-primary"
            />
          </div>
          <div class="ml-1">
            講座の登録を行います。必要事項を入力してください。予めExcelなどで一覧表を作成し、それを転記すると効率良く登録できます。
          </div>
        </div>
      </div>
    </b-alert>
    <iframe
      v-if="inputForm.lessonURI"
      :src="movieURI"
      width="640"
      height="400"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
      class="videoScope"
    />
    <b-card>
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            v-if="useValueDicList.includes(item.type)"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-dic="inputForm[item.key]"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            :description="item.description || ''"
            @inputData="input(item, $event)"
          />
          <InputParts
            v-else-if="useValueListList.includes(item.type)"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-list="typeof inputForm[item.key] !== 'undefined'? inputForm[item.key] : []"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type === 'checkboxplain' ? item.select: []"
            @inputData="input(item, $event)"
          />
          <InputParts
            v-else-if="item.type === 'learningCategoryInput'"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-num="typeof inputForm[item.key] !=='undefined'? inputForm[item.key]: 0"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            @inputData="input(item, $event)"
          />
          <InputParts
            v-else
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="input(item, $event)"
          />
        </b-row>
        <div v-if="errorList && errorList.length > 0">
          <b-alert
            variant="danger"
            show
            class="alertSpace"
          >
            <div class="alert-body">
              <div class="">
                {{ errorMSG }}
              </div>
              <div
                v-for="(item, index) in errorList"
                :key="index"
                class=""
              >
                ・<b>{{ item }}</b>
              </div>
            </div>
          </b-alert>
        </div>
        <b-row>
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="text-secondary"
              class="mr-1"
              @click="cancel"
            >
              キャンセル
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm"
            >
              <span>保存する</span>
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <SuccessBasic
      :show-alert="isShowModal"
      @refetch="move"
    />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner, BAlert, BAvatar, BBreadcrumb,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
import SuccessBasic from '@/components/ui/alert/SuccessBasic.vue'
import choice from '@/components/conf/JPCAREER様_学習PF_選択肢.json'
import { ref, onMounted, watchEffect } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
    BAlert,
    BAvatar,
    SuccessBasic,
    BBreadcrumb,
  },
  directives: {
    Ripple,
  },
  setup() {
    const selected = ref([])
    const movieURI = ref('')
    const useValueDicList = ['checkbox', 'selectSingle', 'lessonCategorySelect', 'radio', 'basicInputNumeric']
    const useValueListList = ['checkboxplain', 'qualificationInput', 'serviceCategory', 'tagForm']
    const { route, router } = useRouter()
    const {
      status,
      inputForm,
      menulist,
      errorMSG,
      errorList,
      confirm,
      getLesson,
      isShowModal,
      categoryA,
      options,
    } = useLesson()
    const breadcrumbItems = ref([
      {
        text: '登録サービス一覧',
        to: { name: 'teacher-lesson' },
        icon: 'HomeIcon',
      },
      {
        text: '',
        to: {
          name: 'teacher-lessonregistration-list',
          query: {
            categoryA: route.value.query.categoryA,
            categoryB: route.value.query.categoryB,
          },
        },
        key: 'subTitle',
      },
      {
        text: '登録講座の編集',
        active: true,
      },
    ])
    watchEffect(() => {
      if (inputForm.value && inputForm.value.lessonURI) {
        const uri = inputForm.value.lessonURI.split('/')
        movieURI.value = `https://player.vimeo.com/video/${uri[3]}?h=${uri[4]}`
      }
    },
    {
      deep: true,
    })
    const fetch = async () => {
      if (route.value.query.met === 'edit') {
        inputForm.value = await getLesson()
      }
    }
    const move = () => router.push({
      name: 'teacher-lessonregistration-list',
      query: {
        categoryA: route.value.query.categoryA,
        categoryB: route.value.query.categoryB,
      },
    })
    const cancel = () => move()
    const title = ref('')
    const subTitle = ref('')
    onMounted(() => {
      if (route.value.query.met === 'edit') fetch()
      title.value = categoryA.find(v => Number(v.value) === Number(route.value.query.categoryA))
      subTitle.value = options.categoryB.find(v => Number(v.value) === Number(route.value.query.categoryB))
      breadcrumbItems.value.find(v => v.key === 'subTitle').text = `${title.value.labelName} - ${subTitle.value.labelName}`
    })
    return {
      inputForm,
      status,
      selected,
      useValueDicList,
      useValueListList,
      errorMSG,
      errorList,
      menulist,
      choice,
      confirm,
      move,
      cancel,
      isShowModal,
      movieURI,
      breadcrumbItems,
    }
  },
  // watch: {
  //   inputForm: {
  //     handler() {
  //       if (this.inputForm.lessonURI) {
  //         const uri = this.inputForm.lessonURI.split('/')
  //         this.movieURI = `https://player.vimeo.com/video/${uri[3]}?h=${uri[4]}`
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    input(item, event) {
      this.inputForm[item.key] = event
    },
  },
}
</script>

<style scoped>
.copy_ {
  font-size: 1.1rem;
}
.videoScope {
  width: 100%;
  margin: 0 auto;
}
</style>
