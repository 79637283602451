import firebase from 'firebase/app'
import store from '@/store'

export default class CreateLesson {
  constructor() {
    this.label = ''
  }

  async add(payload) {
    const { data } = payload
    const dataDic = { ...data }
    const collection = 'lessonRegistration'

    try {
      const promise = await firebase.firestore().collection(collection).doc()

      dataDic.docID = promise.id
      dataDic.insertTime = new Date()
      dataDic.uid = store.state.userBasic.uid
      dataDic.mail = store.state.userBasic.mail

      await firebase.firestore().collection(collection).doc(promise.id).set(dataDic, { merge: true })

      return { status: 'success' }
    } catch (error) {
      window.console.log('error', error)
      return { status: 'error', msg: error }
    }
  }
}
