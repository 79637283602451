import firebase from 'firebase/app'

export default class GetDataListWhereKeyValue {
  constructor() {
    this.label = ''
  }

  async fetch(payload) {
    const { collection, targetKey, targetValue } = payload
    const dataList = []

    try {
      await firebase.firestore().collection(collection)
        .where(targetKey, '==', targetValue)
        .get()
        .then(sn => {
          if (!sn.empty) {
            sn.forEach(doc => {
              dataList.push(doc.data())
            })
          }
        })

      const response = {
        status: 'success',
        data: dataList,
      }
      return response
    } catch (error) {
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
