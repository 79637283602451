import CreateOrUpdate from '@/components/js/compositionAPI/lesson/data/components/CreateOrUpdate'

/**
 * エントリポイント
 * collectionを引数で設定した汎用的なデータ処理
 * @returns メソッド
 */
export default function useData() {
  /**
   * add or update
   * @param {*} payload
   */
  const createOrUpdate = async payload => {
    const target = new CreateOrUpdate(payload)
    await target.play()
  }

  /**
   * 入力フォームにおける入力必須の確認
   * 未記入や一度入力した後の削除などを検証する
   * @param {Object} payload
   * @returns {Boolean} true：エラーあり
   */
  const isInvalid = payload => {
    const errorList = []
    const datakeys = Object.keys(payload.inputForm)
    payload.menulist.forEach(item => {
      if (item.required && !datakeys.includes(item.key)) {
        errorList.push(item.label)
      }
      if (item.required && typeof payload.inputForm[item.key] === 'object') {
        if (Object.keys(payload.inputForm[item.key]).length === 0) errorList.push(item.label)
      }
    })
    if (errorList.length > 0) {
      const errorMSG = '下記の項目を入力してください。'
      return { isInvalid: true, errorMSG, errorList }
    }
    return { isInvalid: false }
  }

  const updateMyPfofileInfo = async payload => {
    window.console.log('memo', payload)
  }
  return {
    createOrUpdate,
    updateMyPfofileInfo,
    isInvalid,
  }
}
