export default class CreateOrUpdate {
  constructor(payload) {
    this.payload = payload
  }

  play() {
    const {
      collection,
      targetKeyLabel,
      targetValue,
      dataList,
    } = this.payload
    window.console.log('🎉', collection, targetKeyLabel, targetValue, dataList)
    this.exitsingDocument()
  }
}
