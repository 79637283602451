import { ref } from '@vue/composition-api'
import menulist from '@/components/conf/teacher/講師_レッスン登録.json'
import { useRouter } from '@core/utils/utils'
import useData from '@/components/js/compositionAPI/lesson/data/useData'
import CreateLesson from '@/firestore/lesson/CreateLesson'
import GetCategoryLessonList from '@/firestore/lesson/GetCategoryLessonList'
import GetDataListWhereKeyValue from '@/firestore/data/GetDataListWhereKeyValue'
import UpdateLesson from '@/firestore/lesson/UpdateLesson'
import store from '@/store'

export default function useLesson() {
  const inputForm = ref({ lessonURI: '' })
  const status = ref(1)
  const errorMSG = ref('')
  const errorList = ref([])
  const lessonList = ref([])
  const isShowModal = ref(false)

  const categoryA = [
    {
      value: 1,
      labelName: 'タッチケア編',
      id: 2953,
      subTitle: '身体･精神･魂のバランスと調和を目指す新しいマッサージです。',
      description: 'こちらの講座ではLesson1 ハンドケア〜Lesson5 頭ケアまで習得すると、頭の先から足の先まで（腹部を除く）大切な人をケアできるようになります。またご自身のセルフケアとして応用することもできます。',
      picURI: 'https://firebasestorage.googleapis.com/v0/b/be-creation-salon.appspot.com/o/branding%2Ftouch-family-care-1.JPG?alt=media&token=20e735dc-3835-4c1e-87b0-5d7c4ac41bb3',
      price: 33000,
      priceNoTax: 30000,
    },
    {
      value: 2,
      labelName: '言葉ケア編',
      id: 8361,
      subTitle: 'タッチケア編と一緒に受講するとさらに効果的です。',
      description: 'こちらの講座ではグルグル思考やモヤモヤ不安をすっきりさせるため、第３世代の最新の認知行動療法といわれているACT（アクト）をベースに、あなたの今の悩みや問題をつくっているものは何か？その原因となる思い込みを解き放ち、自分らしく輝くための意識、思考、行動のベースとなっている基本を学んでいきます。',
      picURI: 'https://firebasestorage.googleapis.com/v0/b/be-creation-salon.appspot.com/o/branding%2Ftouch-family-care-2.PNG?alt=media&token=89f774d6-b09c-4feb-ab23-56770298966d',
      price: 33000,
      priceNoTax: 30000,
    },
  ]
  const options = {
    categoryB: [
      {
        value: 1, labelName: 'メイン講座', memo: 'この講座の本編です。', id: 8424,
      },
      {
        value: 2, labelName: 'ZOOM', memo: 'ZOOMの録画です。', id: 3227,
      },
      {
        value: 3, labelName: '質疑応答', memo: '質問に回答した動画です。', id: 6036,
      },
    ],
  }
  const { route } = useRouter()
  const {
    isInvalid,
  } = useData()

  const sortItem = (a, b) => {
    const A = Number(a.lessonCode.value)
    const B = Number(b.lessonCode.value)
    return A - B
  }
  const sortItemByLessonId = (a, b) => {
    const A = Number(a.lessonId)
    const B = Number(b.lessonId)
    return A - B
  }

  /**
   * 指定のカテゴリ内の講義リストを取得する
   * @param {Object} payload
   */
  const getCategoryLists = async payload => {
    const lesson = new GetCategoryLessonList()
    const res = await lesson.get(payload)
    if (res.status === 'success') return res.data.sort((a, b) => sortItem(a, b))
    return []
  }
  const getCategoryListsScoped = async payload => {
    const res = await getCategoryLists(payload)
    return res.filter(v => v.scope.value === 1)
  }

  const getLesson = async () => {
    const d = {
      collection: 'lessonRegistration',
      targetKey: 'docID',
      targetValue: route.value.query.id,
    }
    const lesson = new GetDataListWhereKeyValue()
    const res = await lesson.fetch(d)
    return res.data[0]
  }

  const createLesson = async payload => {
    const create = new CreateLesson()
    await create.add(payload)
  }

  const updateLesson = async payload => {
    const lesson = new UpdateLesson()
    await lesson.update(payload)
  }

  const confirm = async () => {
    status.value = 2
    const d = {
      inputForm: inputForm.value,
      menulist,
    }
    const isInvalidsRes = isInvalid(d)

    if (isInvalidsRes.isInvalid) {
      errorList.value = isInvalidsRes.errorList
      errorMSG.value = isInvalidsRes.errorMSG
      status.value = 1
      // return
    }

    const dd = {
      data: inputForm.value,
    }
    if (route.value.query.met === 'edit') {
      updateLesson(dd)
    } else {
      inputForm.value.categoryA = route.value.query.categoryA
      inputForm.value.categoryB = route.value.query.categoryB
      createLesson(dd)
    }

    status.value = 1
    isShowModal.value = true
  }

  const isAuthrized = async payload => {
    const d = {
      collection: 'apply',
      targetKey: 'uid',
      targetValue: store.state.userBasic.uid,
    }
    const lesson = new GetDataListWhereKeyValue()
    const res = await lesson.fetch(d)
    const datalist = res.data
    if (datalist.length === 0) return false
    // const flag = Object.keys(datalist.find(v => v.lessonId === Number(payload.lessonId))).length > 0
    const dic = datalist.find(v => v.lessonId === Number(payload.lessonId))
    if (!dic) return false
    const isValid = dic.isValid.value === 1
    return isValid
  }
  return {
    status,
    inputForm,
    categoryA,
    options,
    menulist,
    getCategoryLists,
    getCategoryListsScoped,
    confirm,
    isInvalid,
    errorList,
    errorMSG,
    lessonList,
    getLesson,
    isShowModal,
    isAuthrized,
    sortItem,
    sortItemByLessonId,
  }
}
