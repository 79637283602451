import firebase from 'firebase/app'
import store from '@/store'

export default class UpdateLesson {
  constructor() {
    this.label = ''
  }

  async update(payload) {
    const { data } = payload
    const dataDic = { ...data }
    const collection = 'lessonRegistration'

    try {
      dataDic.updateTime = new Date()
      dataDic.updateUid = store.state.userBasic.uid
      dataDic.updateMail = store.state.userBasic.mail

      await firebase.firestore().collection(collection).doc(dataDic.docID).set(dataDic, { merge: true })

      return { status: 'success' }
    } catch (error) {
      window.console.log('error', error)
      return { status: 'error', msg: error }
    }
  }
}
