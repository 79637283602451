import firebase from 'firebase/app'

export default class GetCategoryLessonList {
  constructor() {
    this.label = ''
  }

  async get(payload) {
    const { categoryA = '', categoryB = '' } = payload
    const collection = 'lessonRegistration'
    const dataList = []

    try {
      await firebase.firestore().collection(collection)
        .where('categoryA', '==', categoryA)
        .where('categoryB', '==', categoryB)
        .get()
        .then(sn => {
          if (!sn.empty) {
            sn.forEach(doc => {
              dataList.push(doc.data())
            })
          }
        })
      return { status: 'success', data: dataList }
    } catch (error) {
      window.console.log('error', error)
      return { status: 'error', msg: error }
    }
  }
}
