<template>
  <div />
</template>
<script>

export default {
  props: {
    toPage: {
      type: String,
      default: '',
    },
    showAlert: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showAlert: {
      handler() {
        if (this.showAlert) this.success()
      },
      immediate: true,
    },
  },
  methods: {
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        if (this.toPage !== '') this.$router.push({ name: this.toPage })
        this.$emit('refetch')
      })
    },
  },
}
</script>
